<template>
  <div class="site">
    <main class="site-content is-flex is-flex-direction-column">
      <div
        v-if="$store.state.user && $store.state.user.space == 'menu'"
        class="menu"
      >
        <section
          class="roomHeader home-menu-header"
          :style="backgroundImage">
            <img
              v-if="$store.state.setup.headerLogo && !isLogoError"
              class="logo"
              :style="`transform: scale(${logoScale})`"
              @error="isLogoError = true"
              :src="srcLogoMenu"
            />
            <AppLogo v-else :style="`transform: scale(${logoScale})`"/>
        </section>
        <menu-component class="main-section" ref="mainSection">
        </menu-component>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appTitle: process.env.VUE_APP_TITLE ?? 'douob.online',
      isLogoError: false,
    };
  },
  computed: {
    srcLogoMenu() {
      return (
        this.$store.state.setup.headerLogo.sizes.mobile.url ||
        this.$store.state.setup.headerLogo.url
      )
    },
    logoScale(){
      return this.$store.state.setup.menuLogoScale ?? 1
    },
    backgroundImage() {
      if (this.$store.state.setup.headerBackground) return `background-image: url(${this.$store.state.setup.headerBackground.url})`
      return null
    },
  }
};
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
// Home menu
.home-menu-header {
  align-items: center;
  justify-content: center;
  background-color: $home-menu-header;
  background-size: auto 100%;
  color: $black-bis;
}

.logo {
  max-height: 100%;
  max-height: 50px;
}

.title {
  font-size: 1.2em;
  font-weight: bolder;
  line-height: 1.2;
  text-align: center;
}
</style>
